<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-06-27 15:38:45
 * @LastEditTime: 2022-08-22 19:20:39
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\views\authCallback\authCallback.vue
-->
<template>
  <div class="authCallback">
    <div @click="$router.push('/')" class="header">
      <img
        title="返回首页"
        class="logo"
        src="https://img.dingdingkaike.com/teacher.dingdingkaike/ico_logo.png"
      />
    </div>
    <div class="lh main">
      <div class="vm">
        <div class="t1"></div>
        <div class="t2">授权成功</div>
        <div class="t3">请返回叮叮开课管理后台继续操作</div>
      </div>
    </div>
    <div class="footer">
      Copyright © {{ new Date().getFullYear() }} 深圳品阔信息技术有限公司
      <a href="https://beian.miit.gov.cn/" target="_blank">
        粤ICP备15114995号-3
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'authCallback',
}
</script>
<style lang="scss" scoped>
.authCallback {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 99999;
  flex-flow: column;
  background: #f5f5f5;
  .header {
    cursor: pointer;
    padding: 7px 20px 0;
    .img {
      width: 115px;
      height: 39px;
    }
  }
  .main {
    flex: 1;
    background: #fff;
    text-align: center;
    margin: 46px 70px 36px;
    .t1 {
      width: 84px;
      height: 84px;
      margin: 0 auto;
      background: url('~@ass/img/1.1.5/success.png') no-repeat;
    }
    .t2 {
      font-size: 20px;
      font-weight: 500;
      margin-top: 30px;
      color: rgba(51, 51, 51, 1);
      line-height: 28px;
    }
    .t3 {
      font-size: 13px;
      margin-top: 10px;
      text-align: center;
      color: rgba(155, 155, 155, 1);
      line-height: 20px;
    }
  }
  .footer {
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    padding-bottom: 12px;
    color: rgba(155, 155, 155, 1);
  }
}
</style>
